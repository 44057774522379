<template>
    <el-dialog title="查看书籍" :visible.sync="dialogVisible" :before-close="handleClose" width="80%" top="5vh">
        <div v-loading="loading">
            <div class="book-header">
                <span>{{ title }}</span>
                <div class="book-header-action"
                    v-if="menuType === 'book' && this.listApproval !== 2 && this.analyseStatus !== 1">
                    <el-button size="small" type="primary" plain @click="handleKnowledge('add')">
                        添加知识点
                    </el-button>
                    <el-button size="small" plain @click="handleKnowledge('edit')">修改
                    </el-button>
                    <el-button plain size="small" type="info" @click="handleReCall()" class="recall-btn">
                        <img src="../../public/icon/path.png" alt=""> 撤销
                    </el-button>
                    <!-- <el-button v-if="treeList.length > 0 && listApproval == 1" size="small" @click="handleCommit"
                        plain>提交审核</el-button>
                    <el-button v-else size="small" plain disabled>提交审核</el-button> -->
                    <!-- <el-button size="small" type="danger" plain>恢复版本</el-button> -->
                </div>
                <div class="book-header-action" v-if="menuType === 'audit'">


                </div>
            </div>
            <div class="book-wrap">
                <div style="width: 37%;">
                    <el-input placeholder="输入关键字搜索目录" v-model="filterText" size="mini" clearable @clear="clearfilter">
                    </el-input>
                    <el-tree class="tree-box" default-expand-all :data="treeList" :props="defaultProps"
                        node-key="contentId" ref="tree" :highlight-current="true" @node-click="handleNodeClick"
                        :expand-on-click-node="false" :filter-node-method="filterNode">
                        <span slot-scope="{ node, data }" class="custom-tree-node">
                            <span :id="`node-${data.contentId}`"
                                :class="{ 'node-title-add': data.contentUpdateStatus === 1, 'node-title-edit': data.contentUpdateStatus === 2, 'node-title-del': data.contentUpdateStatus === 3, }">{{
        node.label }}</span>
                        </span>
                    </el-tree>
                </div>
                <div class="right-content">
                    <virtual-list style="height: 650px; overflow-y: scroll;" :keeps="30" :data-key="'contentId'"
                        :estimate-size="200" :data-sources="bookList" ref="vlist" :data-component="itemComponent"
                        :extra-props="{ handleCardClick, handleDelete, selectId, menuType, knowledgeId, filterText, _getBookContent }" />
                </div>
            </div>
            <div v-if="menuType === 'audit'" slot="footer" class="book-footer">
                <el-button @click="handleAudit(4)" :loading="auditLoading">不通过</el-button>
                <el-button type="primary" @click="handleAudit(3)" :loading="auditLoading">通过</el-button>
            </div>
        </div>
        <el-dialog :title="dialogFormTitle" :visible.sync="dialogFormVisible" append-to-body width="50%"
            :close-on-click-modal="false">
            <div style="font-size: 15px; font-weight: 600;margin-bottom: 10px;">
                当前目录位置: {{ Object.keys(bookAddObj).length === 0 ? '根目录' : bookAddObj.bookContentTitle }}
            </div>
            <el-form :model="form" size="small" v-if="dialogFormVisible">
                <el-form-item label="标题(目录名)">
                    <el-input v-model="form.bookContentTitle" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-tag :key="tag" v-for="tag in keywordTags" closable :disable-transitions="false"
                        style="margin-right: 10px; margin-top: 5px;" @close="handleKeywordClose(tag)">
                        {{ tag }}
                    </el-tag>
                    <el-input style="width: 20%; margin-right: 10px;" class="input-new-tag" v-if="keywordVisible"
                        v-model="keywordValue" ref="saveTagInput" size="small"
                        @keyup.enter.native="handleKeywordConfirm" @blur="handleKeywordConfirm">
                    </el-input>
                    <el-button v-if="keywordVisible" class="button-new-tag" size="small"
                        @click="handleKeywordConfirm">确认</el-button>
                    <el-button v-else class="button-new-tag" size="small" @click="showKeywordInput">+ 新增关键字</el-button>
                    <!-- <el-input v-model="form.bookContentKeyword" autocomplete="off"></el-input> -->
                </el-form-item>
                <el-form-item label="知识点(问题)">
                    <span slot="label">知识点(问题):</span>
                    <el-button v-if="bookTypeId == 3" type="primary" size="small"
                        @click="createKnowledgeQuestion">生成知识点问题</el-button>
                    <el-input style="margin-top: 5px;" v-model="form.bookContentKnowledgePoints" autocomplete="off"
                        type="textarea" :rows="11"></el-input>
                </el-form-item>
                <el-form-item label="内容">
                    <el-input v-model="form.bookContent" autocomplete="off" type="textarea" :rows="11"></el-input>
                </el-form-item>
                <el-form-item label="图片">
                    <el-upload :action="`/omswebapi/book/uploadImage`" :headers="headers" list-type="picture-card"
                        :on-remove="handlePicRemove" :on-preview="handlePictureCardPreview" :on-success="upLoadSuccess"
                        :file-list="fileList">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </el-form-item>
                <el-form-item v-if="contentTypeId == 1" label="是否AI回答">
                    <el-radio v-model="form.isAiAnswer" :label="true">是</el-radio>
                    <el-radio v-model="form.isAiAnswer" :label="false">否</el-radio>
                </el-form-item>
                <el-form-item v-if="form.isAiAnswer" label="关键槽位">
                    <el-select v-model="form.slotId" multiple placeholder="请选择">
                        <el-option v-for="item in slotOptions" :key="item.slotId" :label="item.slotName"
                            :value="item.slotId">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="handleFormConfirm">确 定</el-button>
            </div>
            <el-dialog :visible.sync="dialogPreview" append-to-body>
                <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
            <el-dialog title="生成相似问题" :visible.sync="dialogCreateQuestion" append-to-body width="20%">
                <el-form style="margin-top: 20px;" v-if="dialogCreateQuestion" :model="questionForm" ref="questionForm">
                    <el-form-item label="生成数量:" prop="generateQuestionCount">
                        <el-input-number v-model="questionForm.count" auto-complete="off"></el-input-number>
                    </el-form-item>
                </el-form>
                <div style="padding-top: 0px; " slot="footer" class="dialog-footer">
                    <el-button @click="dialogCreateQuestion = false">取 消</el-button>
                    <el-button type="primary" @click="handleQuestionFormConfirm" :loading="questionLoading">确
                        定</el-button>
                </div>
            </el-dialog>
        </el-dialog>
    </el-dialog>
</template>

<script>
import {
    getBookContent,
    addBookContent,
    editBookContent,
    deleteBookContent,
    getBookContentForAudit,
    bookAudit,
    commitBookForAudit,
    getSlotListPage,
    knowledgeBookContent,
    generateQuestion,
    bookContentRollback
} from "../api/api";
import VirtualList from 'vue-virtual-scroll-list'
import Mark from 'mark.js'
import Item from './BookMenuItem'
export default {
    name: 'BookMenu',
    props: {
        title: String,
        visible: Boolean,
        bookId: Number,
        approval: Number,
        menuType: String,
        refreshList: {
            type: Function,
            default: () => { }
        },
        contentTypeId: Number,
        knowledgeId: Number,
        analyseStatus: Number,
        bookTypeId: Number
    },
    data() {
        return {
            host: process.env.VUE_APP_BASE_URL,
            headers: {
                'Authorization': `Bearer ${window.localStorage.getItem("Token")}`,
            },
            itemComponent: Item,
            dialogVisible: this.visible,
            treeList: [],
            defaultProps: {
                children: 'children',
                label: 'bookContentTitle'
            },
            bookList: [],
            form: {
                bookContentTitle: '',
                bookContentKeyword: '',
                bookContent: '',
                bookContentKnowledgePoints: '',
                imageUrls: [],
                isAiAnswer: false,
                slotId: [],
            },
            questionForm: {
                count: 0
            },
            formType: 'add',
            dialogFormVisible: false,
            dialogFormTitle: '添加知识点',
            bookAddObj: {},
            loading: false,
            observer: null,
            selectId: null,
            listApproval: null,
            keywordTags: [],
            keywordVisible: false,
            keywordValue: '',
            auditLoading: false,
            slotOptions: [],
            filterText: '',
            dialogPreview: false,
            dialogImageUrl: '',
            fileList: [],
            maxBookTree: 0,
            maxBookContentSort: 0,
            dialogCreateQuestion: false,
            questionLoading: false
        }
    },
    watch: {
        visible(newVal) {
            this.dialogVisible = newVal;
        },
        filterText(val) {
            this.$refs.tree.filter(val);
        }
    },
    methods: {
        handleClose(done) {
            this.dialogVisible = false;
            this.$emit('update:visible', this.dialogVisible);
        },

        //查看书籍列表
        _getBookContent() {
            this.loading = true
            let params = {
                bookId: this.bookId
            };
            getBookContent(params).then(res => {
                if (res.data.status === 200) {
                    if (res.data.response !== null) {
                        this.resToTree(res.data.response)
                    } else {
                        this.loading = false
                    }
                } else {
                    this.loading = false
                }
            })
        },
        //待审核书籍列表
        _getBookContentForAudit() {
            this.loading = true
            let params = {
                bookId: this.bookId
            };
            getBookContentForAudit(params).then(res => {
                if (res.data.status === 200) {
                    if (res.data.response !== null) {
                        this.resToTree(res.data.response)
                    } else {
                        this.loading = false
                    }
                } else {
                    this.loading = false
                }
            })
        },
        //待审核书籍列表
        _knowledgeBookContent() {
            this.loading = true
            let params = {
                bookId: this.bookId,
                knowledgeId: this.knowledgeId
            };
            knowledgeBookContent(params).then(res => {
                if (res.data.status === 200) {
                    if (res.data.response !== null) {
                        this.resToTree(res.data.response)
                    } else {
                        this.loading = false
                    }
                } else {
                    this.loading = false
                }
            })
        },
        splitPath(str) {
            return str.split(',').filter(item => item).map(Number);
        },
        listToTree(srcList) {
            let result = []
            // reduce收集所有节点信息存放在对象中
            let nodeInfo = srcList.reduce((data, node) => (data[node.treeId] = node, data), {})
            srcList.forEach((node) => {
                if (!node.parentId) {
                    this.maxBookTree = this.splitPath(node.bookContentTree)
                    this.maxBookContentSort = node.bookContentSort
                    result.push(node)
                    return
                }
                let parent = nodeInfo[node.parentId]
                if (parent) {
                    parent.children = parent.children || []
                    parent.children.push(node)
                } else {
                    if (node.contentUpdateStatus !== 0) {
                        result.push(node)
                    }
                }
            })
            return result
        },

        resToTree(res) {
            res.map((item, index) => {
                this.loading = false
                const path = this.splitPath(item.bookContentTree);
                const newArr = path.filter((value, index, self) => {
                    return (value !== 0 || self.indexOf(value) === index);
                });
                item.treeId = "," + newArr.join(',') + ","
                if (newArr.length === 2 && newArr[newArr.length - 1] !== 0) {
                    item.parentId = "," + newArr[0] + ","
                }
                if (newArr.length > 2) {
                    newArr.pop()
                    if (newArr[newArr.length - 1] === 0) {
                        newArr.pop()
                    }
                    item.parentId = "," + newArr.join(',') + ","
                }
                item.vIndex = index
            })
            this.bookList = res
            //遍历树
            this.treeList = this.listToTree(res)
        },

        handleNodeClick(data) {
            this.bookAddObj = data
            this.$refs.vlist.scrollToIndex(data.vIndex)
            this.selectId = data.contentId
            setTimeout(() => {
                // const node = document.getElementById(`card-${data.contentId}`)
                // if (node) {
                //     this.$nextTick(() => {
                //         node.scrollIntoView({
                //             // behavior: 'smooth',
                //             block: 'center',
                //             inline: 'start'
                //         })
                //     })
                // }
                this.bookList.map((item) => {
                    const cardMarker = new Mark(document.getElementById(`card-${item.contentId}`))
                    cardMarker.unmark()
                    cardMarker.mark(
                        this.filterText,
                        {
                            className: 'text-selected',
                            element: 'span',
                            each: (element) => {
                                element.style.color = '#409EFF'
                            }
                        }
                    )
                })
            }, 50)
        },
        handleCardClick(data) {
            this.bookAddObj = data
            //滚动
            this.selectId = data.contentId
            this.$refs.tree.setCurrentKey(data.contentId);
            const node = document.getElementById(`node-${data.contentId}`)
            if (node) {
                this.$nextTick(() => {
                    node.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'nearest'
                    })
                })
            }

        },
        handleFormConfirm() {
            //序号区分
            let bookContentTree = ''
            let bookContentSort = null
            if (Object.keys(this.bookAddObj).length == 0) {
                bookContentSort = this.maxBookContentSort + 1
                bookContentTree = `,${(this.maxBookTree[0] + 1)},`
            } else {
                if (this.bookAddObj.children) {
                    let arr = this.splitPath(this.bookAddObj.children[this.bookAddObj.children.length - 1].bookContentTree)
                    arr[arr.length - 1]++
                    let newArr = []
                    arr.map((item) => {
                        newArr.push(item < 10 ? '0' + item : item.toString())
                    })
                    bookContentTree = "," + newArr.join(',') + ","
                    bookContentSort = arr[arr.length - 1]
                } else {
                    bookContentTree = this.bookAddObj.bookContentTree + "01,"
                    bookContentSort = 1
                }
            }

            let keywordTagsStr = ''
            if (this.keywordTags) {
                this.keywordTags.map((item, index) => {
                    if (index === this.keywordTags.length - 1) {
                        keywordTagsStr = keywordTagsStr + item
                    } else {
                        keywordTagsStr = keywordTagsStr + item + "|"
                    }
                })
            }
            let slotIds = ''
            if (this.form.slotId) {
                this.form.slotId.map((item, index) => {
                    if (index === this.form.slotId.length - 1) {
                        slotIds = slotIds + item
                    } else {
                        slotIds = slotIds + item + ","
                    }
                })
            }

            if (this.formType == 'add') {
                let params = {
                    ...this.form,
                    bookContentParentId: this.bookAddObj.bookContentParentId,
                    bookId: this.bookId,
                    bookContentTree,
                    bookContentSort,
                    bookContentKeyword: keywordTagsStr,
                    slotIds
                }
                if (Object.keys(this.bookAddObj).length == 0) {
                    params.bookContentParentId = 0
                } else {
                    params.bookContentParentId = this.bookAddObj.bookContentParentId
                }
                addBookContent(params).then(res => {
                    if (res.data.status == 200) {
                        this.$message({
                            message: '添加成功',
                            type: "success",
                        });
                        this._getBookContent()
                        this.refreshList()
                        this.form = {
                            bookContentTitle: '',
                            bookContentKeyword: '',
                            bookContent: '',
                            bookContentKnowledgePoints: '',
                            imageUrls: [],
                            isAiAnswer: false,
                        }
                        this.keywordTags = []
                        this.listApproval = 1
                        this.dialogFormVisible = false
                        this.bookAddObj = {}
                        setTimeout(() => {
                            this.$refs.tree.filter(this.filterText);
                        }, 500);
                    } else {
                        this.$message({
                            message: '添加失败',
                            type: "error",
                        });
                    }
                })
            }

            if (this.formType == 'edit') {
                let params = {
                    ...this.form,
                    bookContentParentId: this.bookAddObj.bookContentParentId,
                    bookId: this.bookAddObj.bookId,
                    bookContentTree: this.bookAddObj.bookContentTree,
                    bookContentSort: this.bookAddObj.bookContentSort,
                    contentId: this.bookAddObj.contentId,
                    imageUrls: this.bookAddObj.imageUrls,
                    bookContentKeyword: keywordTagsStr,
                    slotIds,
                }
                editBookContent(params).then(res => {
                    if (res.data.status == 200) {
                        this.$message({
                            message: '编辑成功',
                            type: "success",
                        });
                        this._getBookContent()
                        this.refreshList()
                        this.form = {
                            bookContentTitle: '',
                            bookContentKeyword: '',
                            bookContent: '',
                            bookContentKnowledgePoints: '',
                            imageUrls: [],
                            isAiAnswer: false,
                        }
                        this.keywordTags = []
                        this.listApproval = 1
                        this.dialogFormVisible = false
                        this.bookAddObj = {}
                        setTimeout(() => {
                            this.$refs.tree.filter(this.filterText);
                        }, 500);
                    } else {
                        this.$message({
                            message: '编辑失败',
                            type: "error",
                        });
                    }
                })
            }

        },
        handleDelete(data) {
            let text = '确认删除吗？'
            if (data.children && data.children.length > 0) {
                text = '目前节点下有子节点，会全部删除，确认删除吗？'
            }
            this.$confirm(text)
                .then(_ => {
                    deleteBookContent({ contentId: data.contentId }).then(res => {
                        if (res.data.status == 200) {
                            this.$message({
                                message: '删除成功',
                                type: "success",
                            });
                            this._getBookContent()
                            this.refreshList()
                            this.bookAddObj = {}
                        }
                    })
                })
                .catch(_ => { });
        },
        handleCommit() {
            let params = {
                bookId: this.bookId
            };
            this.$confirm('确认提交审核吗？')
                .then(_ => {
                    commitBookForAudit(params).then(res => {
                        if (res.data.status == 200) {
                            this.$message({
                                message: '提交成功',
                                type: "success",
                            });
                            // this._getBookContent()
                            // this.listApproval = 3
                            this.refreshList()
                            this.handleClose()
                        }
                    })
                })
                .catch(_ => { });
        },
        handleAudit(status) {
            this.auditLoading = true
            let params = {
                bookId: this.bookId,
                status
            };
            this.$confirm(status === 3 ? '确认通过审核吗？' : '确认不通过审核吗？')
                .then(_ => {
                    bookAudit(params).then(res => {
                        if (res.data.success) {
                            this.$message({
                                message: '操作成功',
                                type: "success",
                            });
                            this.dialogVisible = false;
                            this.$emit('update:visible', this.dialogVisible);
                            this.refreshList()
                            this.auditLoading = false
                        } else {
                            this.$message({
                                message: `操作失败, ${res.data.message}`,
                                type: "error",
                            });
                            this.auditLoading = false
                        }
                    })
                })
                .catch(_ => {
                    this.auditLoading = false
                });
        },
        handleKeywordClose(tag) {
            this.keywordTags.splice(this.keywordTags.indexOf(tag), 1);
        },

        showKeywordInput() {
            this.keywordVisible = true;
            this.$nextTick(_ => {
                this.$refs.saveTagInput.$refs.input.focus();
            });
        },

        handleKeywordConfirm() {
            let keywordValue = this.keywordValue;
            if (keywordValue) {
                this.keywordTags.push(keywordValue);
            }
            this.keywordVisible = false;
            this.keywordValue = '';
        },
        handleKnowledge(type) {
            if (type === 'edit') {
                if (Object.keys(this.bookAddObj).length == 0) {
                    this.$message.error('请先选择目录')
                    return
                }
                if (this.bookAddObj.contentUpdateStatus === 3) {
                    this.$message.error('该目录已删除,无法修改')
                    return
                }
            }
            if (type === 'add') {
                this.form = {
                    bookContentTitle: '',
                    bookContentKeyword: '',
                    bookContent: '',
                    bookContentKnowledgePoints: '',
                    imageUrls: [],
                    isAiAnswer: false,
                    slotIds: [],
                }
                this.keywordTags = []
                this.dialogFormVisible = true
                this.formType = type
                this.dialogFormTitle = '添加知识点'
            }
            if (type === 'edit') {
                this.keywordTags = this.bookAddObj.bookContentKeyword?.split('|') || []
                console.log(this.keywordTags)
                this.fileList = this.bookAddObj.imageUrls?.map((item) => {
                    return {
                        url: item
                    }
                })
                this.form = {
                    bookContentTitle: this.bookAddObj.bookContentTitle,
                    bookContentKeyword: this.bookAddObj.bookContentKeyword,
                    bookContent: this.bookAddObj.bookContent,
                    bookContentKnowledgePoints: this.bookAddObj.bookContentKnowledgePoints,
                    imageUrls: this.bookAddObj.imageUrls,
                    isAiAnswer: this.bookAddObj.isAiAnswer,
                    slotId: this.bookAddObj.slotIds?.split(',').map((item) => { return Number(item) }),
                }
                this.dialogFormVisible = true
                this.formType = 'edit'
                this.dialogFormTitle = '编辑知识点'
            }
        },
        _getSlotListPage() {
            getSlotListPage({ pageIndex: 1, pageSize: 999 }).then(res => {
                if (res.data.success) {
                    this.slotOptions = res.data.response.data
                }
            })
        },
        filterNode(value, data) {
            const marker = new Mark(document.getElementById(`node-${data.contentId}`))
            const cardMarker = new Mark(document.getElementById(`card-${data.contentId}`))
            marker.unmark()
            cardMarker.unmark()
            data.showQuestion = false
            if (!value) return true;
            if (data.bookContentTitle.indexOf(value) !== -1 || data.bookContent.indexOf(value) !== -1 || data.bookContentKnowledgePoints.indexOf(value) !== -1) {
                marker.mark(
                    value,
                    {
                        className: 'text-selected',
                        element: 'span',
                        each: (element) => {
                            element.style.color = '#409EFF'
                        }
                    }
                )
                cardMarker.mark(
                    value,
                    {
                        className: 'text-selected',
                        element: 'span',
                        each: (element) => {
                            element.style.color = '#409EFF'
                        }
                    }
                )
            }
            if (data.bookContentKnowledgePoints.indexOf(value) !== -1) {
                data.showQuestion = true
            }
            return data.bookContentTitle.indexOf(value) !== -1 || data.bookContent.indexOf(value) !== -1 || data.bookContentKnowledgePoints.indexOf(value) !== -1;
        },
        clearfilter() {
            if (Object.keys(this.bookAddObj).length > 0) {
                this.selectId = this.bookAddObj.contentId
                this.$refs.tree.setCurrentKey(this.bookAddObj.contentId);
                const node = document.getElementById(`node-${this.bookAddObj.contentId}`)
                if (node) {
                    this.$nextTick(() => {
                        node.scrollIntoView({
                            behavior: 'smooth',
                            block: 'center',
                            inline: 'nearest'
                        })
                    })
                }
            }
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogPreview = true;
        },
        upLoadSuccess(res) {
            if (res.success) {
                this.form.imageUrls.push(res.response.fileUrl)
            } else {
                this.$message({
                    message: '上传失败, 请重试',
                    type: "error",
                });
            }
        },
        handlePicRemove(file) {
            if (this.formType === 'edit') {
                if (file.response) {
                    this.form.imageUrls = this.form.imageUrls.filter(item => item !== file.response.response.fileUrl)
                } else {
                    this.form.imageUrls = this.form.imageUrls.filter(item => item !== file.url)
                }

            } else {

                this.form.imageUrls = this.form.imageUrls.filter(item => item !== file.response.response.fileUrl)
            }
        },
        createKnowledgeQuestion() {
            this.dialogCreateQuestion = true
        },
        handleQuestionFormConfirm() {
            this.questionLoading = true
            let params = {
                count: this.questionForm.count,
                question: this.form.bookContentTitle,
                answer: this.form.bookContent
            }
            generateQuestion(params).then((res) => {
                if (res.data.status === 200) {
                    this.form.bookContentKnowledgePoints = `${this.form.bookContentKnowledgePoints}\n${res.data.response}`
                    this.$message({
                        message: '生成成功',
                        type: "success",
                    });
                    this.dialogCreateQuestion = false
                    this.questionLoading = false
                } else {
                    this.$message({
                        message: '生成失败, 请重试',
                        type: "error",
                    });
                    this.questionLoading = false

                }
            })
        },
        handleReCall() {
            this.$confirm('将撤销所有删除和修改过的节点，新增节点不会删除，是否确认撤销？')
                .then(_ => {
                    bookContentRollback({
                        bookId: this.bookId,
                        isAll: true
                    }).then((res) => {
                        if (res.data.success) {
                            this.$message({
                                message: '操作成功',
                                type: 'success'
                            });
                            this._getBookContent()
                        } else {
                            this.$message({
                                message: '操作失败, 请重试',
                                type: 'error'
                            });
                        }
                    }).catch((err) => {
                        this.$message({
                            message: '操作失败, 请重试',
                            type: 'error'
                        });
                    })
                })
                .catch(_ => { });
        }
    },
    created() {


    },
    mounted() {
        if (this.menuType === 'book') {
            this._getBookContent();
            this._getSlotListPage()
            this.listApproval = this.approval
        }

        if (this.menuType === 'knowledge') {
            this._knowledgeBookContent();
        }

        if (this.menuType === 'audit') {
            this._getBookContentForAudit();
        }

    },
};
</script>

<style scoped>
::v-deep .el-dialog__header {
    padding-top: 10px;
}

::v-deep .el-dialog__title {
    font-size: 15px;
}

::v-deep .el-dialog__body {
    padding-top: 0px;

}


.custom-tree-node {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.book-wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.book-header {
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.book-header-action {
    display: flex;
    justify-content: space-around;

}

.recall-btn {
    display: flex;
    align-items: center;
}

.recall-btn img {
    width: 12px;
    height: 12px;
    margin-right: 5px;
}

.book-footer {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 100%;
}

.dialog-footer {
    text-align: center;
}

.book-keyword {
    width: 93%;
}

.tree-box {
    /* width: 37%; */
    margin-top: 20px;
    overflow-y: scroll;
    max-height: 630px;
}

.right-content {
    width: 65%;
    /* overflow-y: scroll; */
    padding: 20px;
}

.box-card {
    width: 93%;
    min-height: 200px;
    /* box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1); */
    margin-bottom: 20px;
    padding: 14px 17px;
    background: #F2F3F5;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.box-card-active {
    box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.1);
    background: #FFF;
    border-radius: 4px;
    border: 1px solid #409EFF;
}

.book-action {
    font-size: 15px;
    font-weight: bolder;
    margin-bottom: 15px;
}

.node-title-add {
    color: #07B700
}

.node-title-edit {
    color: #FFB143
}

.node-title-del {
    color: #FF5050
}
</style>